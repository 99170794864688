import {useMe} from '@/haligator-factory';
import {routes} from 'ff-common';
import {Flex, VStack, Image} from '@chakra-ui/react';
import {useHalState} from '@synako/haligator';
import {Outlet} from 'react-router-dom';
import {PartieGaucheConnexion} from './PartieGaucheConnexion';
import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {useTranslation} from 'react-i18next';
import {imageOptimisee} from '../mediatheque/optimisation';

export const AuthLayout: React.FC = () => {
  const meHook = useHalState(useMe());
  const {t} = useTranslation('common');

  if(meHook.data?.type === 'contact_client') {
    window.location.replace(routes.client.accueil.path);
  }

  return !meHook.loading ? (
    <Flex minHeight="100vh" direction={{base: 'column', md: 'row'}} bg='white'>

      {/* Partie gauche - cachée sur mobile */}
      <PartieGaucheConnexion />

      {/* Partie droite */}
      <VStack flex={1} justifyContent="center" alignItems="center" padding="30px" maxWidth={{base: '100%', md: '700px'}} margin="0 auto" position="relative">

        {/* Logo pour mobile */}
        <Image src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)} alt={t('global.alt_logo_france_frais')} marginX='auto' w="300px" mt="30px" display={{base: 'block', md: 'none'}} />

        <Outlet />
      </VStack>
    </Flex>
  ) : null;
};
