import {Outlet, useNavigate} from 'react-router-dom';
import {useHalState} from '@synako/haligator';
import {useMe} from '@/haligator-factory';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {connectionRoute} from '@/domains/auth/Connection';
import {useProtectedRedirect} from 'ff-common';

export const Protected: React.FC = () => {
  const meHook = useHalState(useMe());
  const navigate = useNavigate();

  useProtectedRedirect(meHook, ()=>{navigate(connectionRoute.path);});

  return <CheckLoaded
    hook={meHook}
    render={({data}) => {
      if (data.type === 'visiteur') {
        //NOTE(Nathan) Ne sera affiché qu'en dev; on préfère une redirection en prod
        return <></>;
      }
      return <Outlet />;
    }} />;
};
