import {useHalState} from '@synako/haligator';
import {Box, Button} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleRemplacementCatalogue} from '../panier/components/ModaleRemplacementCatalogue';
import {useApi} from '@/haligator-factory';

export const DebugModaleRemplacementCatalogueView: React.FC = () => {
  const article = useHalState(useApi().follow('get-article', {code_article: '164379'}));
  const go = () => {
    NiceModal.show(ModaleRemplacementCatalogue, {
      articleData: article.data, 
      remplacerSubmit: console.log, 
    });
  };
  return <Box>
    <Button
      variant='outline'
      disabled={article.loading}
      onClick={go}>
      Remplacer
    </Button>
  </Box>;
};
