import {Button, Heading, HStack, Icon, Input, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useSearchClient} from 'ff-common';
import {useHalState} from '@synako/haligator';
import {useNomenclature} from '@/haligator-factory';
import {InfoOutlineIcon} from '@chakra-ui/icons';

export const DebugNomenclatureView: React.FC = () => {
  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [catStdSansWeb, setCatStdSansWeb] = useState<string[]>([]);
  const nomenclatureHook = useHalState(useNomenclature());
  const [codeArticle, setCodeArticle] = useState('');
  const [hasWebCategory, setHasWebCategory] = useState<string | null>(null);
  const [catStdPremierNiveau, setCatStdPremierNiveau] = useState<string[]>([]);

  // recuperation des résultats
  useEffect(() => {
    if(search.indexIsBuilt) {
      search.searchItems({}).then((r) => {
        setItemResults(r);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt]);

  // obtention cat std de premier niveau et cat std sans cat web
  useEffect(() => {
    if (itemResults && search.indexes && Object.keys(search.indexes.catalogue_idx.data.docs.docs).length > 0) {
      const catStdPremierNiveauArray = Object.entries(itemResults.facets?.['cat_std']?.values ?? {})
        .filter(([code]) => Number.isInteger(Number(code)))
        .map(([code]) => code);

      setCatStdPremierNiveau(catStdPremierNiveauArray);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const docs = search.indexes.catalogue_idx.data.docs.docs as Record<string, any>;
      const catStdAvecWeb = new Set<string>();

      Object.values(docs).forEach(doc => {
        // veririfcation s'il y a une cat std
        if (doc.cat_std && doc.cat_std.length > 0) {
          const premierNiveau = doc.cat_std[0];
          // verification s'il y a une cat web
          if (doc.cat_web && doc.cat_web.length > 0) {
            catStdAvecWeb.add(premierNiveau);
          }
        }
      });

      const catStdSansWebArray = catStdPremierNiveauArray
        .filter(cat => !catStdAvecWeb.has(cat))
        .sort();

      setCatStdSansWeb(catStdSansWebArray);
    }
  }, [itemResults, search.indexes]);

  // cat web de premier niveau
  const catWebPremierNiveau = Object.entries(itemResults?.facets?.['cat_web']?.values ?? {})
    .filter(([code]) => Number.isInteger(Number(code)));

  // cat web sans produits
  const catWebSansProduits = catWebPremierNiveau
    .filter(([, count]) => count === 0)
    .map(([code]) => code);

  // cat web écartée(s)
  const catWebEcartees = ['109'];

  // obtention des libelles des categories standard
  const getLibelleStd = (code: string) => {
    const category = nomenclatureHook.data?.standard.categories.find(cat => cat.id === code);
    return category ? category.libelle : 'Libellé non trouvé';
  };

  // obtention des libelles des categories web
  const getLibelleWeb = (code: string) => {
    const category = nomenclatureHook.data?.web.categories.find(cat => cat.id === code);
    return category ? category.libelle : 'Libellé non trouvé';
  };

  // categories web affichée(s)
  const catWebAffichees = catWebPremierNiveau.filter(([code]) =>
    !catWebSansProduits.includes(code) && !catWebEcartees.includes(code)
  );

  // produits sans categorie web
  const produitsSansCatWeb = Object.values(search.indexes?.catalogue_idx.data.docs.docs ?? {})
    .filter((doc) => doc.cat_web && doc.cat_web.length === 0)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map((doc) => doc.code);

  // produits avec categorie web
  const produitsAvecCatWeb = Object.values(search.indexes?.catalogue_idx.data.docs.docs ?? {})
    .filter((doc) => doc.cat_web && doc.cat_web.length > 0)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map((doc) => doc.code);

  // nombre de produits total
  const nbreProduitsTotal = search.indexes?.catalogue_idx.data.docs.count;

  // verification si un produit possède une cat web
  const verificationWebCategorie = () => {
    if (produitsAvecCatWeb.includes(codeArticle)) {
      setHasWebCategory('Oui');
    } else if (produitsSansCatWeb.includes(codeArticle)) {
      setHasWebCategory('Non');
    } else {
      setHasWebCategory('inexistant');
    }
  };

  return(
    <>
      <VStack maxW='1000px' m='auto' mt='60px'>
        <VStack w='100%' p='20px'>
          <Heading as='h4' size='md' textAlign='center'>Categories standards de premier niveau sans code_web</Heading>
          <Table width='100%' size="sm">
            <Thead bg='gray.light'>
              <Tr>
                <Th textAlign="center" p={3} color='gray.dark'>Code</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Libellé</Th>
              </Tr>
            </Thead>
            <Tbody>
              {catStdSansWeb.map(code => (
                <Tr key={code}>
                  <Td textAlign="center" p={3}>{code}</Td>
                  <Td textAlign="center" p={3}>{getLibelleStd(code)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>
        <VStack w='100%' p='20px'>
          <Heading as='h4' size='md' textAlign='center'>Nombre de categories Std / Web</Heading>
          <Table width='100%' size="sm">
            <Thead bg='gray.light'>
              <Tr>
                <Th textAlign="center" p={3} color='gray.dark'>Cat std</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Cat web</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td textAlign="center" p={3}>
                  {catStdPremierNiveau.length}
                  <Tooltip
                    label={catStdPremierNiveau.map(id => `${id}: ${getLibelleStd(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
                <Td textAlign="center" p={3}>
                  {catWebPremierNiveau.length}
                  <Tooltip
                    label={catWebPremierNiveau.map(([id]) => `${id}: ${getLibelleWeb(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </VStack>
        <VStack w='100%' p='20px'>
          <Heading as='h4' size='md' textAlign='center'>Informations sur Catégories Web</Heading>
          <Table width='100%' size="sm">
            <Thead bg='gray.light'>
              <Tr>
                <Th textAlign="center" p={3} color='gray.dark'>Cat web totale</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Cat web affichée(s)</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Cat web sans produits (non affichée(s))</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Cat écartée(s)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td textAlign="center" p={3}>
                  {catWebPremierNiveau.length}
                  <Tooltip
                    label={catWebPremierNiveau.map(([id]) => `${id}: ${getLibelleWeb(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
                <Td textAlign="center" p={3}>
                  {catWebAffichees.length}
                  <Tooltip
                    label={catWebAffichees.map(([id]) => `${id}: ${getLibelleWeb(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
                <Td textAlign="center" p={3}>
                  {catWebSansProduits.length}
                  <Tooltip
                    label={catWebSansProduits.map(([id]) => `${id}: ${getLibelleWeb(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
                <Td textAlign="center" p={3}>
                  {catWebEcartees.length}
                  <Tooltip
                    label={catWebEcartees.map(id => `${id}: ${getLibelleWeb(id)}`).join('\n')}
                    hasArrow
                    placement="top"
                    fontSize="md"
                    whiteSpace="pre"
                  >
                    <Icon as={InfoOutlineIcon} ml={2} color="gray.500" />
                  </Tooltip>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </VStack>
        <VStack w='100%' p='20px'>
          <Heading as='h4' size='md' textAlign='center'>Nombre de produits</Heading>
          <Table width='100%' size="sm">
            <Thead bg='gray.light'>
              <Tr>
                <Th textAlign="center" p={3} color='gray.dark'>Nombre de produits total</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Nombre de produits avec cat web</Th>
                <Th textAlign="center" p={3} color='gray.dark'>Nombre de produits sans cat web</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td textAlign="center" p={3}>
                  {nbreProduitsTotal}
                </Td>
                <Td textAlign="center" p={3}>
                  {produitsAvecCatWeb.length}
                </Td>
                <Td textAlign="center" p={3}>
                  {produitsSansCatWeb.length}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </VStack>
        <VStack w='100%' p='20px'>
          <Heading as='h4' size='md' textAlign='center'>Vérification de catégorie web pour un produit</Heading>
          <HStack>
            <Input
              placeholder="Entrez le code article"
              value={codeArticle}
              onChange={(e) => {setCodeArticle(e.target.value);}}
            />
            <Button onClick={verificationWebCategorie}>Vérifier</Button>
          </HStack>
          {hasWebCategory && (
            <Text>
              {hasWebCategory === 'inexistant'
                ? `Ce produit n'existe pas.`
                : `L'article ${codeArticle} ${hasWebCategory === 'Oui' ? 'a une' : "n'a pas de"} catégorie web.`
              }
            </Text>
          )}
        </VStack>
      </VStack>
    </>
  );
};
