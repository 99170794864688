// LeftPane.tsx
import {LOGO_FF_BLANC_URL} from '@/domains/mediatheque/logoFFBlancUrl';
import {Box, Image, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export const PartieGaucheConnexion: React.FC = () => {
  const {t} = useTranslation('common');

  return (
    <Box position="relative" maxWidth="560px" display={{base: 'none', md: 'block'}}>
      <Image src={`${import.meta.env.BASE_URL}/connexion/img/interface5.png`} alt="Arrière-plan" objectFit="cover" w="100%" h="100%" />
      <Image src={LOGO_FF_BLANC_URL} alt="Logo FF Connect" position="absolute" top="20px" left="50%" transform="translateX(-50%)" w="300px" zIndex={100} />
      <Box m="20px">
        <Image src={`${import.meta.env.BASE_URL}/connexion/img/interface4.png`} alt="France" position="absolute" top="150px" width="calc(100% - 20px)" left="50%" transform="translateX(-50%)" padding="10px" filter="sepia(100%) hue-rotate(190deg) saturate(500%)" />
        <Box position="absolute" top="241px" left="80px" w="173px" h="338px" borderRadius="10px" overflow="hidden">
          <Image src={`${import.meta.env.BASE_URL}/connexion/img/interface1.png`} alt="fromagerie" position="absolute" top="50%" left="50%" h="100%" w="auto" objectFit="cover" objectPosition="center" transform="translate(-50%, -50%)" />
        </Box>
        <Box position="absolute" top="299px" left="300px" w="210px" h="125px" borderRadius="10px" overflow="hidden">
          <Image src={`${import.meta.env.BASE_URL}/connexion/img/interface2.png`} alt="Camions France Frais" w="100%" h="100%" objectFit="cover" objectPosition="center" />
        </Box>
        <Box position="absolute" top="447px" left="300px" w="157px" h="160px" borderRadius="10px" overflow="hidden">
          <Image src={`${import.meta.env.BASE_URL}/connexion/img/interface3.png`} alt="Salarié" w="100%" h="100%" objectFit="cover" objectPosition="center" />
        </Box>
      </Box>
      <Text position="absolute" top="664px" left="50%" w="100%" textAlign="center" transform="translate(-50%, -50%)" fontSize="3xl" color="white" fontWeight="600" zIndex={200}>
        {t('authentification.tout_proche')}
      </Text>
      <Text position="absolute" top="700px" left="50%" w="100%" textAlign="center" transform="translate(-50%, -50%)" fontSize="3xl" color="white" fontWeight="700" zIndex={200}>
        {t('authentification.de_vous')}
      </Text>
    </Box>
  );
};
