import {useEffect} from 'react';
import {UseHalStateHook} from '@synako/haligator';
import {MeDesc} from 'api-types/ffconnect-hal';

//Effectue une redirection si l'utilisateur n'est pas loggé
export const useProtectedRedirect = (
  meHook:UseHalStateHook<MeDesc>,
  navigateConnection:()=>void
) => {
  useEffect(() => {
    if (meHook.data == undefined) return;
    if (meHook.data.type === 'visiteur' || meHook.error) {
      navigateConnection();
    }
  }, [meHook, navigateConnection]);
};